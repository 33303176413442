import { PaymentMethodsHubComposable } from './PaymentMethodsHubComposable';
import { CUSTOMER_TYPE, PROVIDER_USED } from '../constants/paymentMethodsHub';

import { providerUtils } from '../paymentMethodsProviders/PaymentProviderComponents';

const mockAddCompanyNewPaymentMethod = jest.fn();
const mockAddUserNewPaymentMethod = jest.fn();
const mockGetCompanyPaymentMethodsCollection = jest.fn();
const mockGetUserPaymentMethodsCollection = jest.fn();
const mockSetCompanyDefaultPaymentMethod = jest.fn();
const mockSetUserDefaultPaymentMethod = jest.fn();
const mockRemoveCompanyPaymentMethod = jest.fn();
const mockRemoveUserPaymentMethod = jest.fn();

jest.mock('../paymentMethodsProviders/PaymentProviderComponents.js', () => ({
  providerUtils: jest.fn().mockResolvedValue({}),
  providerAPI: (() => ({
    addCompanyNewPaymentMethod: mockAddCompanyNewPaymentMethod,
    addUserNewPaymentMethod: mockAddUserNewPaymentMethod,
    getCompanyPaymentMethodsCollection: mockGetCompanyPaymentMethodsCollection,
    getUserPaymentMethodsCollection: mockGetUserPaymentMethodsCollection,
    setCompanyDefaultPaymentMethod: mockSetCompanyDefaultPaymentMethod,
    setUserDefaultPaymentMethod: mockSetUserDefaultPaymentMethod,
    removeCompanyPaymentMethod: mockRemoveCompanyPaymentMethod,
    removeUserPaymentMethod: mockRemoveUserPaymentMethod,
  })),
}));

const storeData = {
  userData: {
    uuid: 'user-uuid',
    company: {
      uuid: 'company-uuid',
    },
    roles: 'default',
  },
  profile: {
    uuid: 'uuid',
  },
  CSOperator: {},
  profileCollection: [],
};

const mockedCandidatePaymentMethod = {
  holderName: 'testUserName',
  paymentMethod: {
    profile: {
      companyUuid: null,
      isAdminInCompanyPays: false,
      isBusinessInCompanyPays: false,
      isBusinessProfile: false,
      profileType: 'user',
      profileUuid: 'bbb',
      userUuid: 'cccc',
    },
  },
};

describe('Given PaymentMethodsHubComposable', () => {
  beforeEach(() => {
    jest.resetModules();
  });
  describe('When the "addNewPaymentMethod" is called', () => {
    describe('And "isBusinessInCompanyPays" is true', () => {
      it('Then the "addCompanyPaymentMethod" is called', async () => {
        storeData.userData.company.company_pays = true;
        storeData.profile.type = CUSTOMER_TYPE.business;
        storeData.profile.uuid = 'uuid';

        const { addNewPaymentMethod } = PaymentMethodsHubComposable(storeData);

        await addNewPaymentMethod(mockedCandidatePaymentMethod);

        expect(mockAddCompanyNewPaymentMethod).toHaveBeenCalledWith('company-uuid', expect.any(Object));
      });
    });

    describe('And "isBusinessInCompanyPays" is false', () => {
      it('Then the "addUserNewPaymentMethod" is called', async () => {
        storeData.userData.company.company_pays = false;
        storeData.profile.type = CUSTOMER_TYPE.user;
        const { addNewPaymentMethod } = PaymentMethodsHubComposable(storeData);

        await addNewPaymentMethod(mockedCandidatePaymentMethod);

        expect(mockAddUserNewPaymentMethod).toHaveBeenCalledWith('user-uuid', expect.any(Object));
      });
    });
  });

  describe('When the "getPaymentMethodsCollection" is called', () => {
    describe('And "isBusinessInCompanyPays" is true', () => {
      it('Then the "getCompanyPaymentMethodsCollection" is called with "company-uuid"', async () => {
        storeData.userData.company.company_pays = true;
        storeData.profile.type = CUSTOMER_TYPE.business;
        const { getPaymentMethodsCollection } = PaymentMethodsHubComposable(storeData);

        await getPaymentMethodsCollection(mockedCandidatePaymentMethod);

        expect(mockGetCompanyPaymentMethodsCollection).toHaveBeenCalledWith('company-uuid');
      });
    });

    describe('And "isBusinessInCompanyPays" is false', () => {
      it('Then the "getCompanyPaymentMethodsCollection" is called with "user-uuid"', async () => {
        storeData.userData.company.company_pays = false;
        storeData.profile.type = CUSTOMER_TYPE.user;
        const { getPaymentMethodsCollection } = PaymentMethodsHubComposable(storeData);

        await getPaymentMethodsCollection(mockedCandidatePaymentMethod);

        expect(mockGetUserPaymentMethodsCollection).toHaveBeenCalledWith('user-uuid');
      });
    });
  });

  describe('When the "setDefaultPaymentMethod" is called', () => {
    describe('And "userIsAdmin" is true and "isBusinessProfile" is true', () => {
      it('Then the "setDefaultPaymentMethod" is called', async () => {
        storeData.userData.company.company_pays = true;
        storeData.userData.roles = CUSTOMER_TYPE.companyAdmin;
        storeData.profile.type = CUSTOMER_TYPE.business;
        const { setDefaultPaymentMethod } = PaymentMethodsHubComposable(storeData);

        await setDefaultPaymentMethod('uuid');

        expect(mockSetCompanyDefaultPaymentMethod).toHaveBeenCalled();
      });
    });

    describe('And "userIsAdmin" is false and "isBusinessProfile" is false', () => {
      it('Then the "setUserDefaultPaymentMethod" is called', async () => {
        storeData.userData.company.company_pays = false;
        storeData.userData.roles = CUSTOMER_TYPE.user;
        const { setDefaultPaymentMethod } = PaymentMethodsHubComposable(storeData);

        await setDefaultPaymentMethod('uuid');

        expect(mockSetUserDefaultPaymentMethod).toHaveBeenCalled();
      });
    });
  });

  describe('When the "getProfileInfo" is called', () => {
    describe('And "isBusinessProfile" is false', () => {
      it('Then the returned profile should be "user"', () => {
        storeData.profile.type = CUSTOMER_TYPE.user;

        const { getProfileInfo } = PaymentMethodsHubComposable(storeData);
        const currentUser = getProfileInfo();

        expect(currentUser).toEqual(
          {
            profileType: 'user',
            userUuid: 'user-uuid',
            companyUuid: null,
            profileUuid: 'uuid',
            isBusinessProfile: false,
            isBusinessInCompanyPays: false,
            isAdminInCompanyPays: false,
            userIsAdmin: false,
            currentCSOperator: {},
          },
        );
      });
    });

    describe('And "isBusinessInCompanyPays" is true', () => {
      it('Then the returned profile should be "company"', () => {
        storeData.profile.type = CUSTOMER_TYPE.business;
        storeData.userData.company.company_pays = true;
        storeData.userData.roles = CUSTOMER_TYPE.companyAdmin;
        storeData.userData.uuid = 'userUUID';
        const { getProfileInfo } = PaymentMethodsHubComposable(storeData);
        const currentUser = getProfileInfo();

        expect(currentUser).toEqual(
          {
            profileType: 'company',
            userUuid: 'userUUID',
            companyUuid: 'company-uuid',
            profileUuid: 'uuid',
            isBusinessProfile: true,
            isBusinessInCompanyPays: true,
            isAdminInCompanyPays: true,
            userIsAdmin: true,
            currentCSOperator: {},
          },
        );
      });
    });
  });

  describe('When the "storage.add" method is called', () => {
    beforeEach(() => {
      jest.resetModules();
      sessionStorage.removeItem('testStorage');
    });
    describe('And has a string as argument ', () => {
      it('Then the string is saved into the sessionStorage', () => {
        const { storage } = PaymentMethodsHubComposable(storeData);
        const elementToStore = 'testString';
        storage.add('testStorage', elementToStore);

        expect(sessionStorage.getItem('testStorage')).toEqual('testString');
      });
    });

    describe('And has a object as argument ', () => {
      it('Then the object is saved into the sessionStorage', () => {
        const { storage } = PaymentMethodsHubComposable(storeData);
        const elementToStore = { obj: 'testString' };
        storage.add('testStorage', elementToStore);

        expect(sessionStorage.getItem('testStorage')).toEqual('{"obj":"testString"}');
      });
    });
  });

  describe('When the "storage.get" method is called', () => {
    beforeEach(() => {
      sessionStorage.removeItem('testStorage');
    });
    describe('And the stored element is a string ', () => {
      it('Then the string is retrieved', () => {
        const { storage } = PaymentMethodsHubComposable(storeData);
        const elementToStore = 'testString';
        sessionStorage.setItem('testStorage', elementToStore);

        expect(storage.get('testStorage')).toEqual('testString');
      });
    });

    describe('And the store element is an object ', () => {
      it('Then the object is retrieved', () => {
        const { storage } = PaymentMethodsHubComposable(storeData);
        const elementToStore = { obj: 'testString' };
        sessionStorage.setItem('testStorage', JSON.stringify(elementToStore));

        expect(storage.get('testStorage')).toEqual({ obj: 'testString' });
      });
    });

    describe('When the "storage.remove" is called', () => {
      it('Then the object is removed from the sessionStorage', () => {
        const elementToStore = 'testString';
        sessionStorage.setItem('testStorage', JSON.stringify(elementToStore));
        const { storage } = PaymentMethodsHubComposable(storeData);

        storage.remove(elementToStore);

        expect(sessionStorage.getItem(elementToStore)).toEqual(null);
      });
    });
  });

  describe('When the "removePaymentMethod" is called', () => {
    describe('And "isBusinessInCompanyPays" is true', () => {
      it('Then the "removeCompanyPaymentMethod" is called', async () => {
        storeData.userData.company.company_pays = true;
        storeData.userData.roles = CUSTOMER_TYPE.companyAdmin;
        storeData.profile.type = CUSTOMER_TYPE.business;
        const { removePaymentMethod } = PaymentMethodsHubComposable(storeData);

        await removePaymentMethod('uuid');

        expect(mockRemoveCompanyPaymentMethod).toHaveBeenCalled();
      });
    });

    describe('And "isBusinessInCompanyPays" is false', () => {
      it('Then the "removeUserPaymentMethod" is called', async () => {
        storeData.userData.company.company_pays = false;
        const { removePaymentMethod } = PaymentMethodsHubComposable(storeData);

        await removePaymentMethod('uuid');

        expect(mockRemoveUserPaymentMethod).toHaveBeenCalled();
      });
    });
  });

  describe('When the "getUtilsFromStoredProvider" is called', () => {
    describe('And the provider is the current', () => {
      it('Then the "providerUtils" is called', async () => {
        storeData.userData.company.company_pays = false;
        const { getUtilsFromStoredProvider } = PaymentMethodsHubComposable(storeData);

        const { storage } = PaymentMethodsHubComposable(storeData);
        storage.add(PROVIDER_USED, 'test');

        await getUtilsFromStoredProvider('test');

        expect(providerUtils).toHaveBeenCalled();
      });
    });
  });
});
